<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingProductProvidersList || isLoadingProductProvidersListArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des produits fournisseur...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows"  :tagguerButton="tagguerButton")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-product-provider-content(ref="sidebarProductProviderContent" @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { productTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarProductProviderContent from "@/components/purchase/product-provider/SidebarProductProviderContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarProductProviderContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "productProvider",
      uid: "productProviders",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tagsSelected: [],
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un produit fournisseur",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "productType",
        filterTemplate: "productTypeFilterTemplate",
        headerText: "Type",
        width: 140,
        maxWidth: 140,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "productTypeTemplate",
        visible: true,
      },
      {
        type: "string",
        field: "reference",
        headerText: "Référence",
        width: 140,
        minWidth: 140,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "string",
        field: "company.name",
        headerText: "Fournisseur",
        width: 140,
        minWidth: 140,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        visible: true,
      },
      {
        type: null,
        field: "description",
        headerText: "Désignation",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "number",
        format: "formatNumber",
        field: "quantity",
        headerText: "Qté",
        width: 100,
        minWidth: 100,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "unit.label",
        headerText: "Unité",
        width: 150,
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "netPrice",
        headerText: "Prix d'achat HT",
        width: 150,
        minWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingProductProvidersList",
      "isLoadingProductProvidersListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters.productProvidersListArchived;
      } else {
        return this.$store.getters.productProvidersList;
      }
    },
  },
  async created() {
    await this.getProductProviders({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions(["getProductProviders", "getProductProvidersArchived"]),
    productTypeTranslate,
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/new-product-provider" });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getProductProvidersArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getProductProviders({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (!this.archive) {
        this.$refs["sidebarProductProviderContent"].getProductProviderDetails(
          event.rowData.id
        );
      } else {
        this.$refs[
          "sidebarProductProviderContent"
        ].getProductProviderArchivedDetails(event.rowData.id);
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
